<template>
  <div>
    <br>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable&&!disabled">
        </c-upload>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'traning-picture',
  props: {
    traning: {
      type: Object,
      default: () => ({
        sopEmergencyTrainingId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
    attachInfo: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'TRAINING_PICTURE',
          taskKey: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  watch: {
    'saveCall.saveCallData'() {
      this.attachInfo.isSubmit = this.$_.clone(this.saveCall.saveCallData)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.editable = this.$route.meta.editable;
      // code setting
      // list setting
    },
  }
};
</script>
